import React from 'react';

export default function VoteBullets() {
  return (
    <ul className="vertical-margin">
      <li className="vertical-margin">Tally votes for best table topics response</li>
      <li className="vertical-margin">Bridge the gap for hybrid meetings while eliminating paper</li>
      <li className="vertical-margin">We do not store any data; this is a private, anonymous service</li>
    </ul>
  );
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTiVotes = /* GraphQL */ `
  mutation CreateTiVotes($input: CreateTiVotesInput!) {
    createTiVotes(input: $input) {
      Id
      VoteId
      Vote
      __typename
    }
  }
`;
export const updateTiVotes = /* GraphQL */ `
  mutation UpdateTiVotes($input: UpdateTiVotesInput!) {
    updateTiVotes(input: $input) {
      Id
      VoteId
      Vote
      __typename
    }
  }
`;
export const deleteTiVotes = /* GraphQL */ `
  mutation DeleteTiVotes($input: DeleteTiVotesInput!) {
    deleteTiVotes(input: $input) {
      Id
      VoteId
      Vote
      __typename
    }
  }
`;
